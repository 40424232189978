<template>
  <navbar></navbar>
  <div>
    <main class="page-content">
      <div class="account-page-area">
      <div class="">
          <div class="row">
            <div class="col-lg-3 account_border-right">
              <sideMenuVendor></sideMenuVendor>
            </div>
            <div class="col-lg-9">
              <h3 class="comm-title">Active Auctions</h3>
              <div v-if="!items.length"><NotFound></NotFound></div>
              <div class="row" v-else>
                <div
                  class="col-lg-4"
                  v-for="VehicleDetails in items"
                  :key="VehicleDetails.vehId"
                  style="margin-bottom: 15px; padding: 0px"
                >
                  <div>
                    <div class="">
                      <!--new-main-tile-->
                      <section>
                        <div class="" style="margin: 0px 6px">
                          <div class="">
                            <div class="carTileWrap shine-overlay">
                              <div class="shine"></div>
                              <div
                                class="cwrapImage"
                                style="position: relative"
                              >
                                <router-link
                                  :to="{
                                    name: 'VehicleDeailsPending',
                                    params: { url_key: VehicleDetails.vehId },
                                  }"
                                >
                                  <img
                                    :src="path + VehicleDetails.vehImage1"
                                    class="img-fluid img-responsive border5px"
                                /></router-link>

                                <div class="topleft">
                                  <div>
                                    <CountDown
                                      :customstyle="'background-color: beige;'"
                                      :deadline="VehicleDetails.aucExtDate"
                                    ></CountDown>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div class="vehicleName">
                                  <router-link
                                    :to="{
                                      name: 'VehicleDeailsPending',
                                      params: {
                                        url_key: VehicleDetails.vehId,
                                      },
                                    }"
                                  >
                                    <h5>
                                      {{ VehicleDetails.brandName }}
                                      {{ VehicleDetails.modelName }}
                                      {{ VehicleDetails.manYear }}
                                    </h5>
                                  </router-link>
                                </div>
                                <div>
                                  <!-- <div class="row" style="margin: 0px">
                                    <div class="col-6">
                                      <div>
                                        <i
                                          class="fas fa-tachometer-alt pdtListico"
                                        ></i>
                                        <div class="pdtListicon">
                                          <span>Odometer</span>
                                          <p class="fontWeightbidlist">
                                            {{ VehicleDetails.kmClocked }}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-6">
                                      <div>
                                        <i
                                          class="fas fa-map-marker pdtListico"
                                        ></i>
                                        <div class="pdtListicon">
                                          <span>Location</span>
                                          <p class="fontWeightbidlist">
                                            {{ VehicleDetails.locName }}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-6">
                                      <div>
                                        <i class="fas fa-car pdtListico"></i>
                                        <div class="pdtListicon">
                                          <span>Trim Level</span>
                                          <p class="fontWeightbidlist">Base</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-6">
                                      <div>
                                        <i
                                          class="fas fa-tachometer-alt pdtListico"
                                        ></i>
                                        <div class="pdtListicon">
                                          <span>Disclosure</span>
                                          <p class="fontWeightbidlist">
                                            Not Available
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-6">
                                      <div>
                                        <i class="fa fa-fire pdtListico"></i>
                                        <div class="pdtListicon">
                                          <span>Mileage</span>
                                          <p class="fontWeightbidlist">
                                            Not Actual
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-6">
                                      <div>
                                        <i
                                          class="fas fa-tachometer-alt pdtListico"
                                        ></i>
                                        <div class="pdtListicon">
                                          <span>As Is</span>
                                          <p class="fontWeightbidlist">
                                            Not Available
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div> -->
                                  <hr style="margin: 0px" />
                                  <div class="row">
                                    <div class="col-12">
                                      <div class="text-center">
                                        <div>
                                          <p class="tile-small-txt">
                                            CURRENT BID PRICE
                                          </p>
                                          <h4
                                            style="
                                              font-size: 18px;
                                              font-weight: 600;
                                            "
                                          >
                                            ${{
                                              VehicleDetails.latestBidAmount
                                            }}
                                          </h4>
                                          <router-link
                                            class="tile-bid-now"
                                            tabindex="-1"
                                            :to="{
                                              name: 'VehicleDeailsPending',
                                              params: {
                                                url_key: VehicleDetails.vehId,
                                              },
                                            }"
                                            >View Details</router-link
                                          >
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-6">
                                      <div class="text-center"></div>
                                    </div>
                                  </div>

                                  <div></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="uren-paginatoin-area">
                <div class="row">
                  <div class="col-lg-12">
                    <PageNation
                      :vechiclePage="AuctionsDetails"
                      @pageChange="PageRout"
                    ></PageNation>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import NotFound from "@/components/modules/NotFound.vue";
import CountDown from "@/components/modules/CountDown.vue";
import userService from "@/services/user.service";
import sideMenuVendor from "@/components/sideMenuVendor.vue";
import PageNation from "@/components/modules/PageNation.vue";
import Config from "@/config";
import navbar from "@/components/navbar.vue";
export default {
  name: "Bids",
  components: {
    sideMenuVendor,
    CountDown,
    PageNation,
    NotFound,
    navbar
  },
  data() {
    return {
      items: [],
      AuctionsDetails: [],
      path: Config.BASE_URL_IMG,
      perPage: 12,
      currentPage: 1,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.userinfo = JSON.parse(localStorage.getItem("user"));
      if (typeof this.userinfo.sellerId != "undefined") {
        this.getLiveAuctionBySellerId(this.userinfo.sellerId);
      }
    }
  },
  methods: {
    PageRout(userData) {
      // console.log(userData);
      this.items = userData;
    },
    getLiveAuctionBySellerId(sellerId) {
      let id = sellerId;
      if (id == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getLiveAuctionBySellerId(id)
          .then((response) => {
            this.AuctionsDetails = response.data.Data;
            this.items = this.AuctionsDetails.slice(
              (this.currentPage - 1) * this.perPage,
              this.currentPage * this.perPage
            );
         
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
  },
};
</script>
<style scoped>
.carTileWrap {
  padding: 5px;
  background-color: #f2f2f2 !important;
  border-radius: 5px;
  box-shadow: 0px 0px 4px #a2a2a2;
}
.uren-sidebar-catagories_area .uren-sidebar_categories {
  background-color: #ebeff5;
  padding: 25px;
  margin-bottom: 20px;
  border-radius: 5px;
}
.uren-sidebar-catagories_area
  .uren-sidebar_categories
  .uren-categories_title
  h5 {
  text-transform: uppercase;
  margin-bottom: 0;
  padding-bottom: 5px;
  color: black;
}
.uren-sidebar-catagories_area
  .uren-sidebar_categories
  .sidebar-checkbox_list
  > li
  > a {
  color: black;
}
.subBtnzz {
  background-image: linear-gradient(to bottom right, #1d1b2b, #004a6d);
  color: white;
  font-weight: 600;
}
.bidamountzz {
  font-size: 20px;
  font-weight: 600;
}
.heart-stroke {
  fill: white;
  stroke: #666;
  stroke-width: 2px;
  opacity: 1;
  transform-origin: center center;
}
.button-bidzzz.active .heart-stroke {
  opacity: 0;
}
.heart-full {
  opacity: 0;
  transform-origin: 50% 50%;
}
.button-bidzzz.active .heart-full {
  opacity: 1;
}
.heart-lines {
  stroke-width: 2px;
  display: none;
}

.button-bidzzz:not(.active):hover .heart-stroke {
  -webkit-animation: pulse 1s ease-out infinite;
  animation: pulse 1s ease-out infinite;
}

.button-bidzzz.animate .heart-full {
  -webkit-animation: heart 0.35s;
  animation: heart 0.35s;
}
.button-bidzzz.animate .heart-lines {
  -webkit-animation: lines 0.2s ease-out forwards;
  animation: lines 0.2s ease-out forwards;
  display: block;
}

@-webkit-keyframes lines {
  0% {
    stroke-dasharray: 6;
    stroke-dashoffset: 16;
  }
  100% {
    stroke-dasharray: 13;
    stroke-dashoffset: 18;
  }
}

@keyframes lines {
  0% {
    stroke-dasharray: 6;
    stroke-dashoffset: 16;
  }
  100% {
    stroke-dasharray: 13;
    stroke-dashoffset: 18;
  }
}
@-webkit-keyframes heart {
  0% {
    transform: scale(1);
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(1.2);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  35% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  75% {
    transform: scale(1.1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heart {
  0% {
    transform: scale(1);
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(1.2);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  35% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  75% {
    transform: scale(1.1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@-webkit-keyframes pulse {
  0% {
    opacity: 1;
    transform-origin: center center;
    transform: scale(1);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    opacity: 1;
    transform-origin: center center;
    transform: scale(1);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.bidColorbtn {
  padding: 3px 20px;
}
.bidColorbtnRed {
  padding: 3px 20px;
}
.bidBtn {
  padding: 3px 6px !important;
}
.marginbidtop {
  margin-top: 5px;
}
.spanzz {
  font-size: 12px;
}

.subBtnzz {
  background-image: linear-gradient(to bottom right, #1d1b2b, #004a6d);
  color: white;
  font-weight: 600;
}
.bidamountzz {
  font-size: 20px;
  font-weight: 600;
}
.heart-stroke {
  fill: white;
  stroke: #ddd;
  stroke-width: 2px;
  opacity: 1;
  transform-origin: center center;
}
.button-bidzzz.active .heart-stroke {
  opacity: 0;
}
.heart-full {
  opacity: 0;
  transform-origin: 50% 50%;
}
.button-bidzzz.active .heart-full {
  opacity: 1;
}
.heart-lines {
  stroke-width: 2px;
  display: none;
}

.button-bidzzz:not(.active):hover .heart-stroke {
  -webkit-animation: pulse 1s ease-out infinite;
  animation: pulse 1s ease-out infinite;
}

.button-bidzzz.animate .heart-full {
  -webkit-animation: heart 0.35s;
  animation: heart 0.35s;
}
.button-bidzzz.animate .heart-lines {
  -webkit-animation: lines 0.2s ease-out forwards;
  animation: lines 0.2s ease-out forwards;
  display: block;
}

@-webkit-keyframes lines {
  0% {
    stroke-dasharray: 6;
    stroke-dashoffset: 16;
  }
  100% {
    stroke-dasharray: 13;
    stroke-dashoffset: 18;
  }
}

@keyframes lines {
  0% {
    stroke-dasharray: 6;
    stroke-dashoffset: 16;
  }
  100% {
    stroke-dasharray: 13;
    stroke-dashoffset: 18;
  }
}
@-webkit-keyframes heart {
  0% {
    transform: scale(1);
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(1.2);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  35% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  75% {
    transform: scale(1.1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heart {
  0% {
    transform: scale(1);
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(1.2);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  35% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  75% {
    transform: scale(1.1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@-webkit-keyframes pulse {
  0% {
    opacity: 1;
    transform-origin: center center;
    transform: scale(1);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    opacity: 1;
    transform-origin: center center;
    transform: scale(1);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
